import "primereact/resources/primereact.min.css";
import "@code2io/fe-theme/dist/themes/dark/theme.css";
import "@code2io/fe-theme/dist/themes/light/theme.css";
import "@code2io/fe-theme/dist/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@code2io/fe-c2-icons/dist/c2-icons.css";
import C2Router from "./app/routing/routes";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useEffect } from "react";
import zipy from "zipyai";
import { getDynamicConfigValue } from "./app/utils/dynamicConfig";
import { EnvironmentConstants } from "./environmentConstants";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectUser } from "./features/auth/authSlice";
import { useUnimpersonateUserMutation } from "./app/services/auth";
import { useEvaluationContextUpdate } from "./app/components/FrigadeOnboarding/index";
import { useLocation } from "react-router-dom";
import { setVisible } from "./app/components/C2Modal/C2ModalSlice";
import mixpanel from "mixpanel-browser";

const App = () => {
  const dispatch = useAppDispatch();
  const zipyEnabled =
    getDynamicConfigValue(EnvironmentConstants.ZIPY_ENABLED) === "true";
  const zipyInit = zipyEnabled;
  const user = useAppSelector(selectUser);
  const [unimpersonate] = useUnimpersonateUserMutation();
  const location = useLocation();

  const onErrorListener = (event: ErrorEvent) => {
    if (event.message.includes("Failed to fetch dynamically imported module")) {
      window.location.reload();
    }
  };

  useEffect(() => {
    addEventListener("error", onErrorListener);
    dispatch(setVisible(false));
    if (location.pathname.includes("studio/")) {
      document.documentElement.setAttribute("theme", "dark");
    } else {
      document.documentElement.setAttribute("theme", "light");
    }

    return () => {
      removeEventListener("error", onErrorListener);
    };
  }, [location]);

  useEvaluationContextUpdate("location", location);

  useEffect(() => {
    if (
      getDynamicConfigValue(EnvironmentConstants.MIXPANEL_ENABLED) == "true"
    ) {
      mixpanel.init("b55508c05cd4d9987bdef67fb0ab1623", {
        debug: false,
        track_pageview: "full-url",
        persistence: "localStorage"
      });
    }
    if (zipyInit) {
      zipy
        .init(getDynamicConfigValue(EnvironmentConstants.ZIPY_API_KEY), {
          releaseVer: getDynamicConfigValue(
            EnvironmentConstants.ZIPY_RELEASE_VERSION
          )
        })
        .then(() => {
          if (user) {
            zipy.identify(user.email, {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email
            });
          }
          (window as any).zipy = zipy;
        })
        .catch((err) => console.error(err));
    }
  }, [user, zipyInit]);

  return (
    <>
      {user?.impersonatorId && (
        <>
          <div
            className="absolute bg-white-500 p-2 text-white"
            style={{ bottom: 0, right: 0, zIndex: 9999, opacity: 0.4 }}
          >
            <span>Impersonated ({user.email}) </span>
            <a
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                unimpersonate()
                  .then(() => {
                    window.location.href = "/";
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Back to my account
            </a>
          </div>
        </>
      )}
      <C2Router />
      <ConfirmDialog />
    </>
  );
};

export default App;
