import * as Frigade from "@frigade/react";
import React, { useEffect, useState } from "react";
import FrigadeFlow from "./FrigadeFlow";
import type { Flow } from "@frigade/js";
import { useAppSelector } from "../../../hooks";
import { selectUser } from "../../../../features/auth/authSlice";

const FrigadeOnboarding = () => {
  const { frigade } = Frigade.useFrigade();
  const { addProperties } = Frigade.useUser();
  const user = useAppSelector(selectUser);
  const [flows, setFlows] = useState<Flow[]>([]);

  useEffect(() => {
    if (user) {
      addProperties({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      })
        .then(() => {
          console.log(`User identified ${user.email}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [addProperties, user]);

  useEffect(() => {
    const getFlows = async () => {
      const res = await frigade.getFlows();
      setFlows(res);
    };

    getFlows().catch((err) => console.log(err));
  }, [frigade]);

  if (!user) {
    return null;
  }

  return (
    <>
      {flows.map((flow) => (
        <React.Fragment key={flow.id}>
          <FrigadeFlow flow={flow} flowId={flow.id} />
        </React.Fragment>
      ))}
    </>
  );
};

export default FrigadeOnboarding;
