import type {
  ASLState,
  ChoiceState,
  FlowDefinition,
  MapState,
  PassState,
  SucceedState,
  TaskState
} from "@code2io/fe-engine/dist/flowExecutor";
import type { ChoiceRules } from "@code2io/fe-engine/dist/common-flow-utils";
import {
  hasEnd,
  hasNext,
  isChoiceState,
  isTaskState
} from "@code2io/fe-engine/dist/flowExecutor";
import { v4 as uuidv4 } from "uuid";
import type { CallableInput } from "../components/helpers/FlowSelector";
import { NodeTypes } from "../constants";
import {
  getEndLoopNodeIdFor,
  getStartNode,
  isEndLoopId,
  isEndSwitchId
} from "../data";
import { notNull } from "../typeGuards";
import type {
  FlowManifestWithId,
  IConvertedFlow,
  IFlow,
  IFlowExecutables,
  INode,
  INodeData,
  NodeMap,
  StateMap
} from "../types";
import { ChoiceBuilder } from "./choiceBuilder";
import { emptyFlow, ResourceTypes } from "./constants";
import { convertBackendFlow } from "./convertBackendFlow";
import {
  beResource,
  feResource,
  getExecutionTypes,
  getNextStepsFromStep,
  getNodesBetween,
  getUsedVariables,
  isBackendChoiceStep,
  isBackendMapState,
  isBackendStep
} from "./converterUtils";
import { LoopBuilder } from "./loopBuilder";
import { TaskBuilder } from "./taskBuilder";
import { getExecutionType } from "../config";

export interface IWatchList {
  variable: IWatchListVariableItem;
  flowId: string;
}

interface IWatchListVariableItem {
  name: string;
  scope: string;
}

type BEFlowsType = (FlowManifestWithId & { next?: string; end?: boolean })[];
interface FailState {
  Error: string;
  Cause: string;
  Type: "Fail";
}

export function getStepManifest(
  node: INode<INodeData>,
  executionType: "fe" | "be"
): ASLState {
  switch (node.type) {
    case "action":
    case "reusableFlow": {
      switch (node.data.actionType) {
        case "end":
          return buildEndNode(node);
        case "transformation":
          return buildPassNode(node);
        default:
          return buildTaskNode(node, executionType);
      }
    }
    case "switch":
      return buildChoiceNode(node);
    case "fail":
      return buildFailNode(node);
    case "success":
      return buildSucceedNode();
    case "break":
      return buildBreakNode();
    default:
      return {
        Type: "Fail",
        Cause: "Error while building flow manifest"
      };
  }
}

function convert(
  nodes: NodeMap,
  executionTypes: Map<string, "fe" | "be">
): IConvertedFlow {
  const startNode = getStartNode(nodes);
  const beFlows: FlowManifestWithId[] = [];

  if (Object.entries(nodes).length === 0 || typeof startNode === "undefined") {
    return {
      feFlow: emptyFlow,
      beFlows: []
    };
  }
  const states: StateMap = {};
  const queue = [startNode];

  while (queue.length > 0) {
    const current = queue.shift()!;
    if (current.type === NodeTypes.Loop) {
      const endNode = nodes[getEndLoopNodeIdFor(current.id)];
      const subFlowNodes = getNodesBetween(nodes, current, endNode);
      const { feFlow: subFeFlow, beFlows: subBeFlows } = convert(
        subFlowNodes,
        executionTypes
      );

      if (isBackendOnlySubflow({ feFlow: subFeFlow, beFlows: subBeFlows })) {
        states[current.id] = buildLoopNode(
          current,
          subBeFlows[0].manifest,
          endNode.next[0] ?? undefined
        );
      } else {
        beFlows.push(...subBeFlows);
        states[current.id] = buildLoopNode(
          current,
          subFeFlow,
          endNode.next[0] ?? undefined
        );
      }
      if (
        endNode.next[0] !== null &&
        (!isEndLoopId(endNode.next[0]) || !isEndSwitchId(endNode.next[0]))
      ) {
        queue.push(nodes[endNode.next[0]]);
      }
      continue;
    } else {
      states[current.id] = getStepManifest(
        {
          ...current,
          next: current.next
            .filter(notNull)
            .filter((next) => !isEndLoopId(next) || !isEndSwitchId(next))
        },
        executionTypes.get(current.id) ?? "fe"
      );
    }
    current.next.filter(notNull).forEach((nextNodeId) => {
      if (nodes[nextNodeId] as INode<INodeData> | undefined) {
        queue.push(nodes[nextNodeId]);
      }
    });
  }
  const intermediateResult = {
    StartAt: startNode.id,
    States: states
  };

  const topLevelBEFlows = findBackendFlows(intermediateResult, executionTypes);
  topLevelBEFlows.forEach((beFlow) => {
    const firstStepId = beFlow.manifest.StartAt;
    const prevFeStep = Object.values(states).find((manifest) => {
      if (isTaskState(manifest)) {
        return manifest.Next === firstStepId;
      } else if (isChoiceState(manifest)) {
        return (
          typeof manifest.Choices.find((rule) => rule.Next === firstStepId) !==
            "undefined" || manifest.Default === firstStepId
        );
      }
      return false;
    });

    const usedVariables = getUsedVariables(beFlow.manifest);
    const usedAppVariables = usedVariables.filter(
      (variable) => variable.scope === "app"
    );
    const usedPageVarbiles = usedVariables.filter(
      (variable) => variable.scope === "page"
    );

    const newTaskManifest = new TaskBuilder(
      feResource(ResourceTypes.executeBackendFlow)
    )
      .setParameters({
        flowId: beFlow.id,
        app: Object.fromEntries(
          usedAppVariables.map((variable) => {
            return [variable.name, `{{ app.${variable.name} }}`];
          })
        ),
        page: Object.fromEntries(
          usedPageVarbiles.map((variable) => {
            return [variable.name, `{{ page.${variable.name} }}`];
          })
        )
      })
      .setNext(beFlow.next)
      .setEnd(beFlow.end)
      .build();

    const newTask = {
      id: uuidv4(),
      manifest: newTaskManifest
    };

    const beFlowStepIds = Object.keys(beFlow.manifest.States);
    beFlowStepIds.forEach((id) => {
      delete states[id];
    });
    states[newTask.id] = newTask.manifest;

    if (prevFeStep) {
      if (isTaskState(prevFeStep)) {
        prevFeStep.Next = newTask.id;
      } else if (isChoiceState(prevFeStep)) {
        const idx = prevFeStep.Choices.findIndex(
          (rule) => rule.Next === firstStepId
        );
        if (idx > -1) {
          prevFeStep.Choices[idx].Next = newTask.id;
        } else {
          prevFeStep.Default = newTask.id;
        }
      }
    } else {
      intermediateResult.StartAt = newTask.id;
    }
  });

  for (const beFlow of topLevelBEFlows) {
    delete beFlow.next;
    delete beFlow.end;
  }

  beFlows.push(...topLevelBEFlows);

  return {
    feFlow: intermediateResult,
    beFlows
  };
}

function toFlowManifest(flow: IFlow, isDraft?: boolean): IFlowExecutables {
  if (!isDraft && !flow.live) throw new Error("Flow is not live");
  //if (!flow.live && !isDraft || (!flow.live && isDraft)) throw new Error("Flow is not live");
  const { type, id, live, draft } = flow;

  const flowWillConvert = isDraft ? draft : live!;

  if (type === "be" || type === "reusable") {
    const { trigger, nodes } = flowWillConvert;
    const manifest = convertBackendFlow(nodes);
    if (type === "reusable") {
      const temp = manifest.StartAt;
      manifest.StartAt = "trigger";
      manifest.States.trigger = buildPassNodeForTrigger(trigger.data, temp);
    }
    return {
      trigger,
      feFlow: null,
      beFlows: [
        {
          id: flow.id,
          manifest
        }
      ]
    };
  }
  const { trigger, nodes } = flowWillConvert;
  const executionTypes = getExecutionTypes(flow);
  const { feFlow, beFlows } = convert(nodes, executionTypes);
  if (
    trigger.data.executionType === "fe" &&
    trigger.data.variables.length > 0
  ) {
    const tempStartAt = feFlow.StartAt;
    feFlow.StartAt = "trigger";
    feFlow.States.trigger = buildPassNodeForTrigger(trigger.data, tempStartAt);
  }
  const result = {
    trigger,
    feFlow: {
      id,
      manifest: feFlow,
      watchList: [] as IWatchList[]
    },
    beFlows
  };
  if (trigger.data.params.watchList) {
    const watchList: IWatchListVariableItem[] = trigger.data.params
      .watchList as IWatchListVariableItem[];
    if (watchList.length > 0)
      watchList.forEach((watchItem: IWatchListVariableItem) => {
        const { name, scope } = watchItem;
        result.feFlow.watchList.push({
          variable: {
            name,
            scope
          },
          flowId: id
        });
      });
  }
  if (isBackendStepsOnly(result)) {
    return {
      feFlow: null,
      trigger: result.trigger,
      beFlows: result.beFlows
    };
  }
  return result;
}

function isBackendOnlySubflow(converted: IConvertedFlow): boolean {
  const { feFlow, beFlows } = converted;

  if (beFlows.length === 0) return false;

  if (Object.keys(feFlow.States).length === 1) {
    const state = feFlow.States[feFlow.StartAt];
    if (isTaskState(state)) {
      if (state.Resource === feResource(ResourceTypes.executeBackendFlow)) {
        return true;
      }
    }
    return false;
  }
  return false;
}

function isBackendStepsOnly(converterOutput: IFlowExecutables): boolean {
  if (!converterOutput.feFlow) return true;

  if (getExecutionType(converterOutput.trigger.data) !== "be") {
    return false;
  }

  if (Object.values(converterOutput.feFlow.manifest.States).length !== 1) {
    return false;
  }

  const state = Object.values(converterOutput.feFlow.manifest.States)[0];

  if (state.Type !== "Task") {
    return false;
  }
  if (state.Resource !== feResource(ResourceTypes.executeBackendFlow)) {
    return false;
  }
  return true;
}

interface ASLStateWithId {
  id: string;
  manifest: ASLState;
}
function findBackendFlows(
  flowManifest: FlowDefinition,
  executionTypes: Map<string, "fe" | "be">
): BEFlowsType {
  const firstStep: ASLStateWithId = {
    id: flowManifest.StartAt,
    manifest: flowManifest.States[flowManifest.StartAt]
  };
  const builder: ASLStateWithId[] = [];
  const beFlows: BEFlowsType = [];
  const visited: { [key: string]: boolean } = Object.fromEntries(
    Object.keys(flowManifest.States).map((id) => [id, false])
  );

  findBackendFlowsRecursive(
    firstStep,
    visited,
    flowManifest.States,
    builder,
    beFlows,
    executionTypes
  );

  return beFlows;
}

function findBackendFlowsRecursive(
  vertex: ASLStateWithId,
  visited: { [key: string]: boolean },
  nodes: StateMap,
  builder: ASLStateWithId[],
  accumulator: BEFlowsType,
  executionTypes: Map<string, "fe" | "be">
) {
  visited[vertex.id] = true;
  let isBEStep = false;
  if (executionTypes.get(vertex.id)) {
    isBEStep = executionTypes.get(vertex.id)! === "be";
  } else {
    isBEStep =
      vertex.manifest.Type === "Choice"
        ? isBackendChoiceStep(vertex.manifest, nodes, builder.length > 0)
        : vertex.manifest.Type === "Map"
        ? isBackendMapState(vertex.manifest)
        : isBackendStep(vertex.manifest, builder.length > 0);
  }

  if (isBEStep) {
    builder.push(vertex);
  } else {
    if (builder.length > 0) {
      const beFlow = createBEFlowManifestFromSteps(builder);
      accumulator.push({ ...beFlow, next: vertex.id });
      builder = [];
    }
  }

  if (Object.values(visited).every(Boolean) && builder.length > 0) {
    const beFlow = createBEFlowManifestFromSteps(builder);
    if (hasEnd(vertex.manifest)) {
      accumulator.push({
        ...beFlow,
        end: true
      });
    } else {
      accumulator.push({ ...beFlow, next: vertex.id });
      builder = [];
    }
  }

  const ids = getNextStepsFromStep(vertex.manifest);
  ids.forEach((id) => {
    const v = nodes[id] as ASLState | undefined;
    if (!v) return;
    const nextV = {
      id,
      manifest: v
    };
    if (!visited[id]) {
      findBackendFlowsRecursive(
        nextV,
        visited,
        nodes,
        builder.length > 0 ? builder : [],
        accumulator,
        executionTypes
      );
    }
  });
}

function createBEFlowManifestFromSteps(
  steps: ASLStateWithId[]
): FlowManifestWithId {
  const id = uuidv4();

  return {
    id,
    manifest: {
      StartAt: steps[0].id,
      States: Object.fromEntries(
        steps.map((step: ASLStateWithId, index: number) => {
          if (index < steps.length - 1) {
            return [step.id, step.manifest];
          }
          const newManifest = {
            ...step.manifest
          };
          if (hasNext(newManifest)) {
            delete newManifest.Next;
            newManifest.End = true;
          }
          return [step.id, newManifest];
        })
      )
    }
  };
}

export function buildLoopNode(
  node: INode<INodeData>,
  iteratorStates: FlowDefinition,
  next?: string
): MapState {
  const nextStep = next && !isEndLoopId(next) ? next : undefined;

  return new LoopBuilder()
    .setParameters(getParameters(node) as MapState["Parameters"])
    .setIterator(iteratorStates)
    .setNext(nextStep)
    .setEnd(!nextStep)
    .build();
}

function buildChoiceNode(node: INode<INodeData>): ChoiceState {
  const switchCases = (getParameters(node) as { cases: string[] }).cases;

  if (switchCases.length === 0) {
    return new ChoiceBuilder()
      .setChoices({
        Choices: [],
        Default: null
      })
      .build();
  }

  if (switchCases.length === 1) {
    return new ChoiceBuilder()
      .setChoices({
        Choices: [
          {
            Variable: switchCases[0],
            BooleanEquals: true,
            Next: node.next[0] ?? ""
          }
        ],
        Default: node.next[1] ?? null
      })
      .build();
  }

  const rules: ChoiceRules = {
    Choices: switchCases.map((expr, index) => {
      return {
        Variable: expr,
        BooleanEquals: true,
        Next: node.next[index] ?? ""
      };
    }),
    Default: node.next[node.next.length - 1] ?? ""
  };

  return new ChoiceBuilder().setChoices(rules).build();
}

function buildFailNode(node: INode<INodeData>): FailState {
  const params = getParameters(node);

  const manifest: FailState = {
    Type: "Fail",
    Cause: params.cause as string,
    Error: params.error as string
  };

  return manifest;
}

function buildSucceedNode(): SucceedState {
  const manifest: SucceedState = {
    Type: "Succeed"
  };

  return manifest;
}

function buildBreakNode(): PassState {
  return {
    Type: "Pass",
    ResultPath: "$$.vars",
    OutputPath: "$$.break",
    End: true
  };
}

function buildEndNode(node: INode<INodeData>): PassState {
  const manifest: PassState = {
    Type: "Pass",
    Result: getParameters(node),
    ResultPath: "$.output",
    OutputPath: "$.output",
    End: true
  };

  return manifest;
}

function buildPassNode(node: INode<INodeData>): PassState {
  return {
    Type: "Pass",
    Parameters: Object.fromEntries(
      node.data.variables.map((variable) => {
        return [variable.label, variable.value];
      })
    ),
    ResultPath: "$$.vars",
    Next: node.next[0] === null ? "" : node.next[0],
    End: node.next.length === 0
  };
}

export function buildPassNodeForTrigger(
  data: INodeData,
  next: string
): PassState {
  if (data.executionType === "fe") {
    const paramObj: { [key: string]: unknown } = {};
    data.variables.forEach((input) => {
      paramObj[input.label] = input.value;
    });
    return {
      Type: "Pass",
      Parameters: paramObj,
      ResultPath: "$$.vars",
      Next: next
    };
  }
  return {
    Type: "Pass",
    Parameters: Object.fromEntries(
      (data.params.callableInputs as CallableInput[]).map((input) => {
        switch (input.type) {
          case "string": {
            return [input.label, `{{ toString(input.${input.label}) }}`];
          }
          case "number": {
            if (input.required) {
              return [input.label, `{{ toNumber(input.${input.label}) }}`];
            }
            return [
              input.label,
              `{{ input.${input.label} == null || input.${input.label} === "" ? null : toNumber(input.${input.label}) }}`
            ];
          }
          case "integer": {
            if (input.required) {
              return [input.label, `{{ toInteger(input.${input.label}) }}`];
            }
            return [
              input.label,
              `{{ input.${input.label} == null || input.${input.label} === "" ? null : toInteger(input.${input.label}) }}`
            ];
          }
          case "boolean": {
            return [input.label, `{{ input.${input.label} ? true : false }}`];
          }
          default: {
            return [input.label, `{{input.${input.label}}}`];
          }
        }
      })
    ),
    ResultPath: "$$.vars",
    Next: next
  };
}

function buildTaskNode(
  node: INode<INodeData>,
  executionType: "fe" | "be"
): TaskState {
  return new TaskBuilder(getTaskResource(node, executionType))
    .setEnd(node.next.length === 0)
    .setNext(node.next[0] === null ? "" : node.next[0])
    .setParameters(getParameters(node))
    .setResultSelector(getResultSelector(node))
    .setResultPath(`$$.vars`)
    .build();
}

function getTaskResource(
  node: INode<INodeData>,
  executionType: "fe" | "be"
): string {
  switch (executionType) {
    case "fe": {
      if (
        [
          "setVariable",
          "setAppVariables",
          "setPageVariables",
          "setComponentProperty",
          "reloadComponentData"
        ].includes(node.data.actionType)
      ) {
        return feResource("dispatchAction");
      }
      return feResource(node.data.actionType);
    }
    case "be": {
      return beResource(node.data.actionType);
    }
    default:
      return feResource(node.data.actionType);
  }
}

function getParameters(node: INode<INodeData>): INodeData["params"] {
  switch (node.data.actionType) {
    case "setVariable": {
      let actionPayload: {
        name: string;
        value: string;
      } & (
        | {
            scope: "page";
            pageId: string;
          }
        | {
            scope: "app";
          }
      ) = {
        name: (node.data.params.variableName as string | undefined) ?? "",
        value: (node.data.params.variableValue as string | undefined) ?? "",
        scope: "app"
      };

      if (node.data.params.scope === "page") {
        actionPayload = {
          ...actionPayload,
          scope: "page",
          pageId: node.data.params.pageId as string
        };
      }
      return {
        actionType: "variables/setVariable",
        actionPayload: actionPayload
      };
    }

    case "setPageVariables": {
      return {
        actionType: "variables/setPageVariables",
        actionPayload: {
          variables: node.data.params.variables
        }
      };
    }

    case "setComponentProperty": {
      return {
        actionType: "variables/setVariableProps",
        actionPayload: {
          variables: node.data.params.variables
        }
      };
    }

    case "reloadComponentData": {
      return {
        actionType: "variables/setVariableProps",
        actionPayload: {
          variables: [
            {
              name: `${node.data.params.component as string}.onLoadCounter`,
              value: `{{ page.${
                node.data.params.component as string
              }.onLoadCounter + 1 }}`,
              scope: "page"
            }
          ]
        }
      };
    }

    case "setAppVariables": {
      return {
        actionType: "variables/setAppVariables",
        actionPayload: {
          variables: node.data.params.variables
        }
      };
    }

    case "db:insert": {
      const params = {
        ...node.data.params,
        type: "insert"
      };
      return Object.fromEntries(
        Object.entries(params).filter(([k]) => k !== "stringValue")
      );
    }
    case "db:bulkInsert": {
      const params = {
        ...node.data.params,
        type: "bulkInsert"
      };
      return Object.fromEntries(
        Object.entries(params).filter(([k]) => k !== "stringValue")
      );
    }
    case "db:get": {
      const params = node.data.params;
      return {
        ...params,
        type: "get"
      };
    }
    case "db:delete": {
      const params = node.data.params;
      return {
        ...params,
        type: "delete"
      };
    }
    case "db:bulkDelete": {
      const params = {
        ...node.data.params,
        type: "bulkDelete"
      };
      return Object.fromEntries(
        Object.entries(params).filter(([k]) => k !== "stringValue")
      );
    }
    case "db:update": {
      const params = {
        ...node.data.params,
        type: "update"
      };
      return Object.fromEntries(
        Object.entries(params).filter(([k]) => k !== "stringValue")
      );
    }
    case "db:bulkUpdate": {
      const params = {
        ...node.data.params,
        type: "bulkUpdate"
      };
      return Object.fromEntries(
        Object.entries(params).filter(([k]) => k !== "stringValue")
      );
    }

    case "db:query": {
      return {
        ...node.data.params,
        type: "query"
      };
    }

    case "transform": {
      return {
        ...node.data.params,
        __ignoreKeys: ["template"]
      };
    }

    case "insider:upsert": {
      return {
        ...node.data.params,
        __ignoreKeys: ["mapping"]
      };
    }
    case "http": {
      const { url, path } = node.data.params as {
        url: string;
        path: { key: string; value: string }[];
      };
      let decodedURI = decodeURI(url);
      try {
        const paths = new URL(decodedURI).pathname
          .split("/")
          .map((p) => decodeURI(p));

        path.forEach((p) => {
          if (paths.includes(`{${p.key}}`)) {
            decodedURI = decodedURI.replace(`{${p.key}}`, p.value);
          } else if (paths.includes(`:${p.key}`)) {
            decodedURI = decodedURI.replace(`:${p.key}`, p.value);
          }
        });
      } catch (e) {
        //console.log(e);
      }
      return { ...node.data.params, url: decodedURI };
    }

    default: {
      if (typeof node.data.params !== "undefined") {
        return node.data.params;
      }
      return {};
    }
  }
}

function getResultSelector(node: INode<INodeData>): unknown {
  return Object.fromEntries(
    node.data.variables.map((variable) => {
      return [variable.label, variable.value];
    })
  );
}

export { toFlowManifest };
