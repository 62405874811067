import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

export interface User {
  firstName: string;
  sub: string;
  iss: string;
  iat: string;
  exp: string;
  cookieHash: string;
  username: string;
  email: string;
  lastName: string;
  developerFlag: boolean;
  impersonatorId?: string;
  partner?: boolean;
  dashboardAccess?: boolean;
  partnerGuestAppId?: string;
  partnerGuestUser?: boolean;
  twoFactorAuthEnabled: boolean;
  twoFactorAuthVerified: boolean;
}

export interface AuthState {
  user: User | null;
  token: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    reset: (state) => {
      state.token = null;
      state.user = null;
    }
  }
});

export const { setUser, setToken, reset } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
