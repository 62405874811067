import * as Frigade from "@frigade/react";
import { useAppSelector } from "../../../hooks";
import { selectUser } from "../../../../features/auth/authSlice";
import type { Flow as FlowType } from "@frigade/js";
import { useEvaluationContextUpdate } from "../index";
import useEvaluate from "../hooks/useEvaluate";
import { useMemo } from "react";
import FrigadeTourItemOverlayPanel from "./FrigadeTourItemOverlayPanel";
import FrigadeTourItemDialog from "./FrigadeTourItemDialog";
import { parseTourItemTitle } from "../utils/frigadeHelpers";

const FrigadeFlow = ({ flowId }: { flowId: string; flow: FlowType }) => {
  const user = useAppSelector(selectUser);
  const { flow } = Frigade.useFlow(flowId);
  const live = useEvaluate(flow?.rawData.data["live-expression"]);

  const flowState = useMemo(() => {
    return {
      isCompleted: flow?.isCompleted,
      isSkipped: flow?.isSkipped,
      isStarted: flow?.isStarted
    };
  }, [flow]);

  useEvaluationContextUpdate(flowId, flowState);

  if (!live) {
    return null;
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <Frigade.Flow flowId={flowId}>
        {({ step }) => {
          const parsedTitle = parseTourItemTitle(
            typeof step.title === "string" ? step.title : ""
          );

          if (parsedTitle.options.dialog) {
            return (
              <FrigadeTourItemDialog
                step={step}
                key={step.id}
                frigadeTourItemId={step.id}
              />
            );
          } else {
            return (
              <FrigadeTourItemOverlayPanel
                step={step}
                key={step.id}
                frigadeTourItemId={step.id}
                placeholder={parsedTitle.options.placeholder}
              />
            );
          }
        }}
      </Frigade.Flow>
    </>
  );
};

export default FrigadeFlow;
