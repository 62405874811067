import type { FlowDefinition } from "@code2io/fe-engine/dist/flowExecutor";
import { NodeTypes } from "../constants";
import {
  getEndLoopNodeIdFor,
  getStartNode,
  isEndLoopId,
  isEndSwitchId
} from "../data";
import { notNull } from "../typeGuards";
import type { INode, INodeData, NodeMap, StateMap } from "../types";
import { emptyFlow } from "./constants";
import { getNodesBetween } from "./converterUtils";
import { buildLoopNode, getStepManifest } from "./toFlowManifest";

export function convertBackendFlow(nodes: NodeMap): FlowDefinition {
  if (Object.keys(nodes).length === 0) {
    return emptyFlow;
  }
  const start = getStartNode(nodes);
  if (typeof start === "undefined") {
    return emptyFlow;
  }

  const states: StateMap = {};
  const queue = [start];

  while (queue.length > 0) {
    const node = queue.shift()!;
    const checkEndSwitchAndReturnNextNode = (id: string) => {
      if (isEndSwitchId(id) && nodes[id].next[0]) {
        return checkEndSwitchAndReturnNextNode(nodes[id].next[0]);
      }
      return id;
    };

    if (node.type === NodeTypes.Loop) {
      const end = nodes[getEndLoopNodeIdFor(node.id)];
      const subflowNodes = getNodesBetween(nodes, node, end);
      const subflowDefinition = convertBackendFlow(subflowNodes);
      states[node.id] = buildLoopNode(
        node,
        subflowDefinition,
        end.next[0] ? checkEndSwitchAndReturnNextNode(end.next[0]) : undefined
      );
      if (end.next[0] !== null && !isEndLoopId(end.next[0])) {
        queue.push(nodes[end.next[0]]);
      }
      continue;
    } else {
      const nextNode = node.next
        .filter(notNull)
        .filter((next) => !isEndLoopId(next))
        .map((id) => {
          return checkEndSwitchAndReturnNextNode(id);
        });
      if (node.type !== NodeTypes.EndSwitch)
        states[node.id] = getStepManifest(
          {
            ...node,
            next: nextNode
          },
          "be"
        );
    }
    node.next.filter(notNull).forEach((nextNodeId) => {
      if (nodes[nextNodeId] as INode<INodeData> | undefined) {
        queue.push(nodes[nextNodeId]);
      }
    });
  }

  return {
    StartAt: start.id,
    States: states
  };
}
